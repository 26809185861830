import round from 'lodash/round';
import { stringify } from 'query-string';
import { toast } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import { depositMoney, getMyCardCount, getPaymentProviders } from '../../microservices/payments';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { isFeatureAvailable } from '../feature';
import { LocalStorage } from '../local-storage/types';
import { logger } from '../logger';
import { getLastActiveProductRoute, getRoute } from '../router';
import { translate } from '../translate';
import { FEATURE } from '../types';
import { isTestUser } from '../user';
import {
    DepositStatus,
    ExistingPaymentMethod,
    PaymentProvider,
    PROVIDER_TYPE,
    PROVIDERS,
    QUICK_DEPOSIT_REDIRECT_STATUS,
} from './types';

export type Card = ExistingPaymentMethod & {
    id: string;
    card_number: string;
    description: string;
    masked_pan: string;
    number: string;
    payer_name: string;
    cardholder: string;
    expiration_date: string;
    expiration_month: string;
    expiration_year: string;
    expiry: string;
    expiry_date: string;
    expirationDate: string;
};

export const FAILED_DEPOSIT_TYPES = {
    BANK_NOT_SUPPORTED: 'Bank not supported',
    CARD_EXPIRED: 'Card expired',
    CARD_NOT_SUPPORTED: 'Card country/currency not supported',
    DEPOSIT_LIMIT_REACHED: 'Deposit limit reached',
    FAILED_BY_PROVIDER: 'Payment failed by provider',
    INCOMPLETE_PAYMENT: 'Customer did not complete the payment',
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    INVALID_DETAILS_ENTERED: 'Invalid details entered',
    MAX_TRANASACTIONS_PER_DAY_REACHED: 'Max transactions per day reached',
    PROVIDER_NETWORK_ERROR: 'Provider network error, contact provider',
    TECHNICAL_ERROR: 'Technical error, contact Payments IT',
    TOO_MANY_CONSECUTIVE_ATTEMPTS: 'Too many consecutive attempts',
    UNMAPPED_ERROR: 'Unmapped error',
    USER_BLACKLISTED: 'User listed',
};

export const PAYMENT_ERROR_MESSAGE = {
    CARD_EXPIRED: 'Your card has expired',
    DECLINED: 'Your payment was declined',
    FAILED_TO_PROCESS: 'Failed to process payment',
    FAILED_TO_VERIFY_CARD: 'Failed to verify card',
} as const;

export type PaymentErrorMessage = typeof PAYMENT_ERROR_MESSAGE[keyof typeof PAYMENT_ERROR_MESSAGE];

export const PROVIDER_UNAVAILABLE_MESSAGE = {
    PROVIDER_DOWN: "Provider's service seems to be down. Please try again later!",
    TOO_MANY_ATTEMPTS: 'Payment failed, you have made too many requests. Please try again later!',
};

export const LOGOS = {
    SEB: '/assets/images/payments/SEB.svg',
    SWEDBANK: '/assets/images/payments/Swedbank.png',
};

export const CHILE_BANKS = {
    '504': 'Banco BBVA Chile',
    '028': 'Banco Bice',
    '055': 'Banco Consorcio',
    '001': 'Banco de Chile',
    '016': 'Banco de Crédito e Inversiones',
    '507': 'Banco del Desarrollo',
    '012': 'Banco del Estado de Chile',
    '051': 'Banco Falabella',
    '009': 'Banco Internacional',
    '039': 'Banco Itaú',
    '053': 'Banco Ripley',
    '037': 'Banco Santander Chile',
    '027': 'Itaú Corpbanca',
    '014': 'Scotiabank Chile',
};

export const ECUADOR_BANKS = {
    '0001': 'Banco Central del Ecuador',
    '0010': 'Banco Pichincha C.A.',
    '0017': 'Banco de Guayaquil S.A',
    '0024': 'Banco City Bank',
    '0025': 'Banco Machala',
    '0029': 'Banco de Loja',
    '0030': 'Banco del Pacifico',
    '0032': 'Banco Internacional',
    '0034': 'Banco Amazonas',
    '0035': 'Banco del Austro',
    '0036': 'Produbanco / Promerica',
    '0037': 'Banco Bolivariano',
    '0039': 'Comercial de Manabi',
    '0042': 'Banco General Ruminahui S.A.',
    '0043': 'Banco del Litoral S.A.',
    '0059': 'Banco Solidario',
    '0060': 'Banco Procredit S.A.',
    '0061': 'Banco Capital',
    '0065': 'Banco Desarrollo de Los Pueblos S.A.',
    '0066': 'Banecuador B.P.',
    '0201': 'Banco Delbank S.A.',
};

export const PERU_SHARED_BANKS = [
    '002',
    '003',
    '007',
    '009',
    '011',
    '018',
    '023',
    '035',
    '038',
    '043',
    '049',
    '053',
    '054',
    '056',
    '800',
    '801',
    '802',
    '803',
    '805',
    '806',
    '808',
];

export const cryptoCurrencyByProvider = {
    [PROVIDERS.PAYHOUND_BITCOIN]: 'BTC',
    [PROVIDERS.PAYHOUND_USDT]: 'USDT',
    [PROVIDERS.PAYHOUND_USDC]: 'USDC',
};

export function cleanCardNumber(number: string): string {
    const regex = new RegExp(`[^0-9]+`, 'g');
    return String(number).replace(regex, '');
}

export function cleanExpirationDate(expirationDate: string): string {
    const regex = new RegExp(`[^0-9/]+`, 'g');
    return String(expirationDate).replace(regex, '');
}

export function depositWithProvider(params: {
    amount: number;
    deviceHash: string;
    methodId?: string;
    provider: string;
    providerParams?: any;
}) {
    const language = getStoreValue(stores.language);
    return depositMoney({ ...params, language });
}

export function fiatToCrypto(amount: number, providerCurrencyRate: number, provider: PROVIDERS) {
    const precision = provider === PROVIDERS.PAYHOUND_BITCOIN ? 8 : 2;
    return round(amount / providerCurrencyRate, precision);
}

export function formatCardNumber(number: string, options?: { clean?: boolean }): string {
    const clean = options?.clean;
    if (isApplePayAccountNumber(number)) {
        return number;
    }
    return clean ? cleanCardNumber(number) : number;
}

export function formatExpirationDate(expirationDate: string): string {
    return `${expirationDate.slice(0, 3)}${expirationDate.slice(5)}`;
}

export function expirationDateTo6Digits(expirationDate: string) {
    return `${expirationDate.slice(0, 3)}20${expirationDate.slice(3)}`;
}

export function validateExpirationDate(expirationDate: string): [boolean, string] {
    const [expirationMonth = '', expirationYear = ''] = expirationDate.split('/');
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    if (
        !expirationMonth ||
        !expirationYear ||
        !expirationMonth.match(/^\d{2}$/) ||
        !expirationYear.match(/^\d{4}$/) ||
        parseInt(expirationMonth) > 12
    ) {
        return [false, 'Invalid expiration date'];
    }
    if (
        parseInt(expirationYear) < currentYear ||
        (parseInt(expirationYear) === currentYear && parseInt(expirationMonth) < currentMonth)
    ) {
        return [false, 'Card has expired'];
    }
    return [true, ''];
}

export function getPaymentProviderImage(fileName) {
    const language = getStoreValue(stores.language);
    return fileName.replace('{{language}}', language);
}

export function getPaymentMethodImage(cardNumber = '') {
    if (cardNumber.startsWith('4')) {
        return `/assets/images/payments/visa-logo.svg`;
    }
    if (cardNumber.startsWith('5')) {
        return `/assets/images/payments/mastercard.svg`;
    }
    if (isApplePayAccountNumber(cardNumber)) {
        return '/assets/images/payments/applepay.svg';
    }
    return `/assets/images/payments/bank.png`;
}

export async function getIsMaxCardsReached({ raiseLimitBy = 0 }) {
    const { current, limit } = await getMyCardCount();
    if (!limit && limit !== 0) {
        return false;
    }
    return current >= limit + raiseLimitBy;
}

export function getDepositReturnRoute(status: DepositStatus, snippetKey?: string) {
    if (snippetKey) {
        return `${getRoute('deposit-return')}/${status}?snippetKey=${snippetKey}`;
    }
    return `${getRoute('deposit-return')}/${status}`;
}

function isApplePayAccountNumber(number: string) {
    return number.toLowerCase().startsWith('apple');
}

export const PAYMENTS_ERROR_CODE = {
    RETRY_WITH_FALLBACK_PROVIDER: 4612,
};

export function redirectDepositTo(state: DepositStatus, snippetKey?: string) {
    const route = isFeatureAvailable(FEATURE.PAYMENT_PACKAGES) ? getRoute('purchase-packages') : getRoute('deposit');
    switch (state) {
        case DepositStatus.AUTO_REFUNDED_3RD_PERSON_METHOD:
        case DepositStatus.AUTO_REFUNDED_NAME_MISMATCH:
        case DepositStatus.CANCELLED:
        case DepositStatus.FAILED: {
            return `${route}?${stringify({
                snippetKey:
                    {
                        [DepositStatus.AUTO_REFUNDED_3RD_PERSON_METHOD]:
                            'deposit-auto-refunded-3rd-person-method-pop-up',
                        [DepositStatus.AUTO_REFUNDED_NAME_MISMATCH]: 'deposit-auto-refunded-name-mismatch-pop-up',
                    }[state] ?? snippetKey,
            })}`;
        }
        case DepositStatus.COMPLETED:
        case DepositStatus.PENDING: {
            return getLastActiveProductRoute();
        }
    }
}

export async function getQuickDepositProviders() {
    let providers: PaymentProvider[] = [];
    if (isFeatureAvailable(FEATURE.QUICK_DEPOSIT) && isTestUser()) {
        try {
            providers = (
                await getPaymentProviders({
                    type: PROVIDER_TYPE.DEPOSIT,
                    quickDeposit: false,
                })
            ).providers;
        } catch (error) {
            logger.error('PaymentsPaymentsService', 'getQuickDepositProviders', error);
        }
    }
    return providers;
}

export function updateOrClearQuickDepositStatus(status?: DepositStatus | typeof QUICK_DEPOSIT_REDIRECT_STATUS) {
    if (status) {
        localStorage.setItem(LocalStorage.QUICK_DEPOSIT_STATUS, status);
    } else {
        localStorage.removeItem(LocalStorage.QUICK_DEPOSIT_STATUS);
    }
}

export function showDepositResultMessage(state: DepositStatus) {
    const resultMessage = {
        [DepositStatus.COMPLETED]: translate('Your deposit was successful.', 'ui.account'),
        [DepositStatus.FAILED]: translate('Your deposit did not go through.', 'ui.account'),
        [DepositStatus.CANCELLED]: translate('Your deposit was cancelled.', 'ui.account'),
        [DepositStatus.PENDING]: translate('Your deposit is pending.', 'ui.account'),
    }[state];
    const toastMethod: TypeOptions = {
        [DepositStatus.COMPLETED]: 'success',
        [DepositStatus.FAILED]: 'error',
        [DepositStatus.CANCELLED]: 'warning',
        [DepositStatus.PENDING]: 'info',
    }[state];

    resultMessage && toastMethod && toast[toastMethod](resultMessage);
}

export function onDepositReturnRedirect(statusOnRedirect: DepositStatus) {
    const currentQuickDepositStatus = localStorage.getItem(LocalStorage.QUICK_DEPOSIT_STATUS);
    const isQuickDepositInProgress =
        isFeatureAvailable(FEATURE.QUICK_DEPOSIT) && currentQuickDepositStatus === QUICK_DEPOSIT_REDIRECT_STATUS;
    if (isQuickDepositInProgress) {
        updateOrClearQuickDepositStatus(statusOnRedirect);
        window.close();
    } else {
        showDepositResultMessage(statusOnRedirect);
    }
}
