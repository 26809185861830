import {
    confirmMessageDelivery,
    getUserSubscriptionPreferences,
    insertUserSubscriptionPreferences,
    loadCrmMessageById,
    loadCrmMessages,
    sendOnSiteMessageStatus,
    updateUserSubscriptionPreferences,
} from '../microservices/crm';
import { getStoreValue } from '../stores/store/utils';
import { storageGet, storageSet } from './storage';
import { translate } from './translate';
import { logger } from './logger';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from './analytics';
import {
    ONSITE_MESSAGE_DISPLAY_TYPE,
    ONSITE_MESSAGE_TAB,
    OnsiteMessage,
    OnsiteMessageStatus,
    OnsiteMessageWithCampaign,
    UserCrmPreferences,
} from '../microservices/crm/types';
import { crm } from '../stores/crm';
import { getIsToasterMessage, getMessagesByType, isUnreadMessage } from './onsite-messages';
import { stores } from '../stores';

export async function getAllCrmMessages() {
    crm.areMessagesLoading.set(true);

    let messages: OnsiteMessage[] = [];
    try {
        messages = await loadCrmMessages();
    } catch (error) {
        logger.error('CrmService', 'getAllCrmMessages', error);
        return;
    }
    const messagesByType = getMessagesByType(messages);
    crm.messagesByType.set(messagesByType);
    showUnreadMessages(messagesByType[ONSITE_MESSAGE_TAB.ALL].messages);

    crm.areMessagesLoading.set(false);
}

async function showUnreadMessages(messages: OnsiteMessage[]) {
    const UNREAD_MESSAGES_POPUP_SEEN_NAME = 'unreadMessagesPopupSeen';
    const user = getStoreValue(stores.user);
    const unreadMessagesPopupSeen = storageGet<Record<string, boolean>>(UNREAD_MESSAGES_POPUP_SEEN_NAME, {});
    const unreadMessages = messages.filter(
        (message) => isUnreadMessage(message) && message.displayType !== ONSITE_MESSAGE_DISPLAY_TYPE.INBOX_MESSAGE,
    );

    if (!unreadMessages.length && user) {
        unreadMessagesPopupSeen[user.id] = false;
        return storageSet(UNREAD_MESSAGES_POPUP_SEEN_NAME, unreadMessagesPopupSeen);
    }

    await trackPendingMessagesAsReceived(unreadMessages);

    const unreadModal = unreadMessages.find((message) => message.displayType === ONSITE_MESSAGE_DISPLAY_TYPE.MODAL);
    if (unreadMessages.length && user && (!unreadMessagesPopupSeen[user.id] || unreadModal)) {
        unreadMessagesPopupSeen[user.id] = true;
        storageSet(UNREAD_MESSAGES_POPUP_SEEN_NAME, unreadMessagesPopupSeen);

        if (unreadMessages.length === 1) {
            crm.onsite.set(unreadMessages[0]);
        } else {
            unreadModal ? crm.onsite.set(unreadModal) : openMultipleMessagesNotice();
        }
    }
}

async function trackPendingMessagesAsReceived(messages: OnsiteMessage[]) {
    const pendingMessages = messages.filter((message) => message.status === 'pending');
    for (const message of pendingMessages) {
        await trackOnSiteMessage(message.id, 'received');
    }
}

function updateInboxMessages(message) {
    const messagesByType = getStoreValue(crm.messagesByType);
    const allMessages = messagesByType[ONSITE_MESSAGE_TAB.ALL].messages;
    allMessages.unshift(message);
    crm.messagesByType.set(getMessagesByType(allMessages));
}

function getGoogleTagPayloadCrmMessage(message?: OnsiteMessageWithCampaign) {
    return {
        on_site_message_name: message?.campaignName,
        on_site_message_id: message?.campaignId,
    };
}

export function closeCrmMessageAndUpdate() {
    const onsiteMessage = getStoreValue(crm.onsite);

    const payload = {
        on_site_message_name: onsiteMessage?.campaignName,
        on_site_message_id: onsiteMessage?.campaignId,
    };
    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_CLOSE_BUTTON_CLICKED, payload);

    if (onsiteMessage?.displayType === ONSITE_MESSAGE_DISPLAY_TYPE.NEW_MESSAGES) {
        crm.isInboxOpen.set(true);
    } else if (onsiteMessage && !onsiteMessage.seenAt) {
        updateMessage(onsiteMessage.id);
    }

    crm.onsite.set(undefined);
}

function openMessage(message: OnsiteMessageWithCampaign, openedBy: 'system' | 'user') {
    if (!['seen', 'cta_clicked', 'bonus_claimed'].includes(message.status)) {
        trackOnSiteMessage(message.id, 'seen');
    }

    crm.openedBy.set(openedBy);
}

export async function openMessageBySystem(messageId: string) {
    const message = await loadCrmMessageById(messageId);

    if (!message) {
        return;
    }

    const isToasterMessage = getIsToasterMessage(message);
    const isInboxMessage = message.displayType === ONSITE_MESSAGE_DISPLAY_TYPE.INBOX_MESSAGE;

    !isInboxMessage && crm.onsite.set(message);

    updateInboxMessages(message);

    if (!isToasterMessage && !isInboxMessage) {
        openMessage(message, 'system');
    }
}

export async function openMessageByUser(message: OnsiteMessage) {
    crm.isInboxOpen.set(false);
    crm.selectedMessage.set(message);
    openMessage(message, 'user');
    await updateMessageOnSeen(message);
}

export async function updateMessageOnSeen(message: OnsiteMessageWithCampaign) {
    if (!message.id || message.seenAt) {
        return;
    }

    storageSet('lastCrmMessageSeenAt', new Date());

    const googleTagPayload = getGoogleTagPayloadCrmMessage(message);
    const openedBy = getStoreValue(crm.openedBy);

    const googleTagMessageShownPayload = {
        ...googleTagPayload,
        on_site_message_open_by: openedBy,
    };

    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_MESSAGE_SHOWN, googleTagMessageShownPayload);

    await updateMessage(message.id);
}

function openMultipleMessagesNotice() {
    crm.onsite.set({
        mascotMessage: getRandomMessage(),
        type: 'MASCOT_ONLY',
        displayType: ONSITE_MESSAGE_DISPLAY_TYPE.NEW_MESSAGES,
    } as OnsiteMessage);
}

async function updateMessage(messageId) {
    const messagesByType = getStoreValue(crm.messagesByType);
    const allMessages = messagesByType[ONSITE_MESSAGE_TAB.ALL].messages;

    allMessages.map((message) => {
        if (message.id === messageId) {
            message.seenAt = new Date();
            message.status = 'seen';
        }
        return message;
    });

    crm.messagesByType.set(getMessagesByType(allMessages));

    await confirmMessageDelivery(messageId);
}

function getRandomMessage() {
    const MULTIPLE_CRM_MESSAGES_NOTICE = [
        translate('crm.multiple-message-notice-one'),
        translate('crm.multiple-message-notice-two'),
        translate('crm.multiple-message-notice-three'),
    ];
    const totalAmountOfMessage = MULTIPLE_CRM_MESSAGES_NOTICE.length;
    const randomized = Math.floor(Math.random() * Math.floor(totalAmountOfMessage));
    return MULTIPLE_CRM_MESSAGES_NOTICE[randomized];
}

async function trackOnSiteMessage(userMessageId: number, status: OnsiteMessageStatus) {
    try {
        await sendOnSiteMessageStatus({ userMessageId, status });
    } catch (error) {
        logger.error('CrmService', 'trackOnSiteMessage', error);
    }
}

export async function loadUserCrmPreferences() {
    try {
        const preferences = await getUserSubscriptionPreferences();
        if (preferences) {
            crm.userPreferences.set(preferences);
        }
    } catch (error) {
        logger.error('CrmService', 'loadUserCrmPreferences', error);
    }
}

export async function insertUserCrmPreferences(body: Omit<UserCrmPreferences, 'user_id'>) {
    try {
        await insertUserSubscriptionPreferences(body);
        crm.userPreferences.set(body);
    } catch (error) {
        logger.error('CrmService', 'insertUserCrmPreferences', error);
    }
}

export async function updateUserCrmPreferences(body: Omit<UserCrmPreferences, 'user_id'>) {
    try {
        await updateUserSubscriptionPreferences(body);
        crm.userPreferences.set(body);
    } catch (error) {
        logger.error('CrmService', 'updateUserCrmPreferences', error);
    }
}

export function handleCtaClick(message: OnsiteMessageWithCampaign) {
    const googleTagPayload = getGoogleTagPayloadCrmMessage(message);

    if (message?.status !== 'cta_clicked') {
        trackOnSiteMessage(message.id, 'cta_clicked');
        stores.crm.selectedMessage.set({ ...message, status: 'cta_clicked' });
    }

    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_CTA_CLICKED, googleTagPayload);

    window.open(message.ctaLink, '_blank');
}

export async function handleMessageBonusClaimed(userMessageId: number) {
    try {
        await sendOnSiteMessageStatus({ userMessageId, status: 'bonus_claimed' });
    } catch (error) {
        logger.error('CrmService', 'handleMessageBonusClaimed', error);
    }
}
