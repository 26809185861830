import React, { InputHTMLAttributes, ReactNode, useRef } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import UiFormLabel from '../form/label/UiFormLabel';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import UiAlert from '../alert/UiAlert';
import { RefCallBack } from 'react-hook-form/dist/types/form';
import DangerousHtml from '../../dangerous-html/DangerousHtml';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'pattern'> {
    error?: FieldError;
    label?: string;
    type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'search';
    pattern?: RegExp;
    validator?: {
        validate: Record<string, (value: any, form?: any) => true | string | Promise<true | string>>;
    };
    dataTest?: string;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    registerReturn?: Omit<UseFormRegisterReturn<string>, 'ref'>;
    registerRef?: RefCallBack;
}
export default function UiTextInput({
    error,
    label,
    dataTest,
    minLength,
    maxLength,
    pattern,
    validator,
    type = 'text',
    startAdornment,
    endAdornment,
    className,
    registerReturn,
    registerRef,
    ...args
}: Props) {
    const inputRef = useRef<HTMLInputElement | null>(null);

    function renderInput() {
        return (
            <input
                {...args}
                {...registerReturn}
                ref={(element) => {
                    registerRef && registerRef(element);
                    inputRef.current = element;
                }}
                type={type}
                data-test={dataTest}
                maxLength={maxLength}
            />
        );
    }

    return (
        <Wrapper className={classNames(className, { error })}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            {startAdornment || endAdornment ? (
                <div className={classNames('input-wrapper', { disabled: args.disabled })}>
                    {startAdornment && (
                        <div className="input-adornment" onClick={() => inputRef.current?.focus()}>
                            {startAdornment}
                        </div>
                    )}
                    {renderInput()}
                    {endAdornment && (
                        <div className="input-adornment" onClick={() => inputRef.current?.focus()}>
                            {endAdornment}
                        </div>
                    )}
                </div>
            ) : (
                renderInput()
            )}
            {error && (
                <UiAlert className="input-error" failure>
                    <DangerousHtml content={error.message || ''} elementType="span" />
                </UiAlert>
            )}
        </Wrapper>
    );
}
