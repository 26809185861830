import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: relative;
    margin-bottom: var(--spacing-12);

    .input-wrapper {
        display: flex;
        overflow: hidden;
        input {
            border: none !important;
            box-shadow: none;
            height: 100%;
            margin: 0;
            padding: 0;
        }
        .input-adornment {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    input:focus,
    input:active,
    .input-wrapper:active,
    .input-wrapper:active input,
    .input-wrapper:focus-within {
        background-color: var(--form-input-bg);
        border-color: var(--form-input-border);
    }
    input {
        padding: 0 0.4rem;
        outline: 0;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:disabled,
    .input-wrapper.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .input-wrapper,
    input {
        width: 100%;
        height: 2.5rem;

        color: var(--form-input-text);
        padding: 0 var(--spacing-12);
        font-size: 0.875rem;

        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        background-color: var(--form-input-bg);

        @media ${mediaQuery.isPhone} {
            height: 3rem;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        border: var(--form-input-border);
        caret-color: var(--form-input-text);
        -webkit-text-fill-color: var(--form-input-text);
        -webkit-box-shadow: 0 0 0 1000px var(--form-input-bg) inset;
        transition: background-color 2147483647s;

        &:focus,
        &:active {
            border-color: var(--form-input-text);
        }
    }

    input::-webkit-input-placeholder {
        color: var(--font-color-subtle);
    }
    input:-moz-placeholder {
        color: var(--font-color-subtle);
    }

    &.error .input-wrapper {
        border-color: var(--color-error);
    }

    &.error > input {
        border-color: var(--color-error);
    }

    .input-error {
        margin-top: var(--spacing-4);
    }
`;
