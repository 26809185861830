import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: flex;
    min-height: calc(100dvh - (60px + ${({ theme }) => theme.mobileNavigation.height} + 16px + 24px));

    .loader-wrap {
        margin: auto;
        position: relative;
        width: 100%;
        min-height: 20px;

        h4 {
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 100%;
            font-size: 1rem;
        }
    }

    @media ${mediaQuery.isLargerThanPhone} {
        min-height: calc(100vh - (${({ theme }) => theme.header.height} + 16px + 24px));
        .loader-wrap h4 {
            font-size: 1.5rem;
        }
    }
`;
