import { getServiceUrl, httpGet, httpPost } from '../services/api';
import {
    BonusBetsResponse,
    BonusType,
    Campaign,
    FreeSpinBonusParams,
    RequestMatchList,
    UserViewBonus,
} from '../services/bonuses/types';
import { Language } from '../services/language/types';
import { setSingleCategoryMarketTypesToStore } from '../services/sports/sport-category';
import { SportCategoryWithMatches } from '../services/sports/types';
import { ObjectValues } from '../services/ts-utils';
import { getUserCountry } from '../services/users/country';
import { getSportsLayout } from '../services/layout/utils';
import { Serialize } from '@staycool/sports-types';

const getUrl = (url) => getServiceUrl('bonuses', url);

export async function getBonusOffers(isAuthenticated: boolean, language: Language) {
    const urlString = `campaigns/available/${isAuthenticated ? '' : 'public/'}${language}`;
    const url = getUrl(urlString);
    return httpGet<Campaign[]>(url);
}

export function submitBonusCode(code: string, isDepositing: boolean, freeSpinsParams?: FreeSpinBonusParams) {
    const url = getUrl('code/');
    return httpPost<string>(url, { code, depositing: isDepositing, freeSpinsParams });
}

export function validateBonusCode(code: string, provider?: string) {
    const url = getUrl('code/validate');
    return httpPost<{
        status: string;
        min_bonus?: Record<string, number>;
        name?: string;
        validation_message?: Record<string, string>;
    }>(url, { code, provider });
}

export async function getBonusCodeValidationResult(code: string) {
    const url = getUrl('public/code/validate');
    return httpPost<{ status: string; validation_message?: Record<string, string> }>(url, { code });
}

export function cancelPokerPendingBonus(bonus) {
    const url = getUrl('user/cancel/');
    return httpPost(url, { user_bonus_id: bonus.id });
}

export function getUserTakenBonuses(lang) {
    const url = getUrl('user/view/');
    return httpGet<UserViewBonus[]>(url, { lang });
}

export async function getOfferedBonuses(params: { bonusType?: ObjectValues<typeof BonusType>; currency: string }) {
    const url = getUrl('bonus-bet/available');
    return httpGet<BonusBetsResponse>(url, params);
}

export async function getBonusMatchesList(
    bonusId: string,
    params: { offset: number; limit: number },
    language: Language,
) {
    const country = getUserCountry();
    const layout = getSportsLayout();
    const url = getUrl(`bonus-bet/matches/${bonusId}`);
    const query: RequestMatchList = {
        ...params,
        language,
        locale: language,
        country,
        layout,
    };
    const categoryWithMatches = await httpGet<Serialize<SportCategoryWithMatches>>(url, query);
    setSingleCategoryMarketTypesToStore(categoryWithMatches);
    return categoryWithMatches?.matches;
}

export async function getBonusBetWageringProgress() {
    const url = getUrl('bonus-bet/wagering-progress');
    return httpGet<{ wageringProgress?: number }>(url);
}

export function getBonusCodeCounter(bonusId) {
    const url = getUrl(`code/counter-id/${bonusId}`);
    return httpGet<number>(url);
}

export async function checkAndValidateUserCode(bonusCode: string) {
    const url = getUrl(`code/check-validate`);
    return httpPost<{
        status?: string;
        min_bonus?: Record<string, number>;
        name?: string;
        validation_message?: Record<string, string>;
    }>(url, { code: bonusCode });
}
