import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getDeviceCategory } from '../services/device';
import { poker } from '../stores/poker/poker';
import { PromotionSource } from '../stores/poker/types';
import { InitGame, PokerLeaderboard, UpcomingTournament } from './poker/types';

const getUrl = (url) => getServiceUrl('poker-playtech-gameslink', url);

export async function updatePokerMaintenanceStatus() {
    const url = getUrl('fo/maintenance-status');

    try {
        const maintenance = await httpGet<{ isMaintenance: boolean; endDate?: string }>(url);
        stores.maintenance.isPokerMaintenance.set(maintenance.isMaintenance);
        stores.maintenance.pokerMaintenanceEndDate.set(maintenance.endDate);
    } catch (error) {
        logger.error('PokerPlaytechGameslinkMicroservice', 'updatePokerMaintenanceStatus', error);
    }
}

export async function initGame(initGame: InitGame): Promise<{ token: string; gameLaunchUrl?: string }> {
    const { isWebClient, tournamentCode, licence } = initGame;
    const platform = getDeviceCategory();
    const url = getUrl('fo/init-game');
    return httpPost(url, { isWebClient, tournamentCode, platform, licence });
}

export async function getCoolbetTokenFromPoker(loginSessionId: string, token: string): Promise<string> {
    return httpPost(getUrl('fo/poker-session'), { loginSessionId, token });
}

export async function loadUpcomingTournaments(ipCountry: string, userCountry: string): Promise<void> {
    try {
        const tournaments = await httpGet<UpcomingTournament[]>(getUrl('fo/upcoming-tournaments'), {
            country: ipCountry,
            userCountry,
        });
        poker.upcomingTournaments.set({
            [PromotionSource.NETWORK]: tournaments.filter(
                (tournament) => tournament.promotionSource === PromotionSource.NETWORK,
            ),
            [PromotionSource.CARDROOM]: tournaments.filter(
                (tournament) => tournament.promotionSource === PromotionSource.CARDROOM,
            ),
        });
    } catch (error) {
        logger.error('PokerPlaytechGameslinkMicroservice', 'loadUpcomingTournaments', error);
    }
}

export async function loadIsPokerBlocked(queryCountryCode: string | undefined): Promise<void> {
    poker.isLoadingPokerBlocked.set(true);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const path = isAuthenticated ? 'fo/is-poker-blocked-auth' : 'fo/is-poker-blocked';
    const url = getUrl(path);
    try {
        const { isPokerBlocked } = (await httpGet(url, { countryCode: queryCountryCode })) as any;
        poker.isPokerBlocked.set(isPokerBlocked);
    } catch (err) {
        poker.isPokerBlocked.set(false);
        logger.error('PokerPlaytechGameslinkMicroservice', 'loadIsPokerBlocked', err);
    } finally {
        poker.isFirstRenderLoading.set(false);
        poker.isLoadingPokerBlocked.set(false);
    }
}

export async function getLeaderboard(code: string, languageCode: string): Promise<PokerLeaderboard> {
    return httpGet(getUrl(`fo/leaderboard/${code}`), { languageCode });
}
