import React, { useEffect, useState } from 'react';
import { getSportsbookIframe } from '../../../microservices/casino';
import Wrapper from './styles';
import { getDeviceCategory } from '../../../services/device';
import { getUserCountry } from '../../../services/users/country';
import { getUserProvince } from '../../../services/user';
import { getLicence } from '../../../services/licence';
import { getStoreValue } from '../../../stores/store/utils';
import { stores } from '../../../stores';
import { Currency } from '../../../services/wallet/types';
import { useStore } from '../../../hooks/useStore';
import { requestLogin } from '../../../services/auth';
import UiDotsLoader from '../../../components/ui/dots-loader/UiDotsLoader';
import UiBox from '../../../components/ui/box/UiBox';
import { translate } from '../../../services/translate';
import { environment } from '../../../stores/environment/environment';
import { getDeviceUuid } from '../../../services/fingerprint';

export default function SportExternalPage() {
    const [iframeUrl, setIframeUrl] = useState<string>();
    const [language] = useStore(stores.language);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ SPORTSBOOK_GAME_VARIANT_ID }] = useStore(environment);

    useEffect(() => {
        if (!isAuthenticated) {
            requestLogin();
            return;
        }

        const payload = {
            lobbyUrl: '',
            gameVariantId: SPORTSBOOK_GAME_VARIANT_ID,
            platform: getDeviceCategory(),
            country: getUserCountry(),
            province: getUserProvince(),
            licence: getLicence(),
            deviceUuid: getDeviceUuid(),
            language,
            gameLaunchPosition: '',
            userCurrency: getStoreValue(stores.wallet)?.currency,
            gamePlayCurrency: Currency.USD,
        };

        getSportsbookIframe(payload).then((url) => setIframeUrl(url));
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        return (
            <UiBox header={translate('You have been logged out. Please log in again.', 'ui.common')}>
                <p>{translate('you-need-to-be-logged-in-to-view-this-page', 'ui.account')}</p>
            </UiBox>
        );
    }

    return iframeUrl ? <Wrapper frameBorder="0" src={iframeUrl} /> : <UiDotsLoader />;
}
