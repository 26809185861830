import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { disableMinBetReminder, getMyCasinoRaces, getPublicCasinoRaces } from '../../microservices/casino-race';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { getCmsImageUrl } from '../cms-image';
import { formattedAmountWithCurrency, getActiveCurrency, getAmountByStaticRate } from '../currency';
import { Language } from '../language/types';
import { logger } from '../logger';
import { showToast } from '../toast';
import { translate } from '../translate';
import { Currency } from '../wallet/types';
import { getGameImageUrlForImage } from './games';
import { AWARD_TYPE, CasinoRace, CasinoRaceAward, CasinoRaceScore, RACE_TYPE } from './types';
import { Toast } from '../toast/types';

const RACE_MIN_BET_MULTIPLIERS = {
    [Currency.CAD]: 1,
    [Currency.CLP]: 500,
    [Currency.EUR]: 1,
    [Currency.NOK]: 10,
    [Currency.PEN]: 4,
    [Currency.SEK]: 10,
    [Currency.USD]: 1,
    [Currency.MXN]: 20,
};

export async function loadActiveCasinoRace() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    try {
        return isAuthenticated ? getMyCasinoRaces() : getPublicCasinoRaces();
    } catch (error) {
        logger.error('CasinoCasinoRaceService', 'loadActiveCasinoRace', error);
    }
}

export async function initializeCasinoRace() {
    stores.casinoRace.active.races.set({} as Record<number, CasinoRace>);
    stores.casinoRace.active.scores.set({} as Record<number, CasinoRaceScore>);
    stores.casinoRace.active.leaderboards.set({} as Record<number, CasinoRaceScore[]>);
    stores.casinoRace.active.positions.set({} as Record<number, number>);
    const activeRaces = await loadActiveCasinoRace();
    if (!activeRaces) {
        return;
    }
    for (const activeRace of activeRaces) {
        raceStartInitialization({ activeRace });
    }
}

export function updateActiveRace(raceId: number, updated = {}) {
    const activeRaces = getStoreValue(stores.casinoRace.active.races);
    const copyActiveRaces = cloneDeep(activeRaces);
    Object.assign(copyActiveRaces[raceId], updated);
    stores.casinoRace.active.races.set(copyActiveRaces);
}

export function getRacePrize(userPosition: number, awards: CasinoRaceAward[], language: Language): string {
    if (userPosition - 1 >= awards.length) {
        return '0';
    }
    const prize = awards[userPosition - 1];
    switch (prize.type) {
        case AWARD_TYPE.FREE_SPINS:
            return `${formattedAmountWithCurrency(prize.amount)} ${translate('FS', 'casino.race')}`;
        case AWARD_TYPE.FREE_MONEY:
            return formattedAmountWithCurrency(prize.amount);
        case AWARD_TYPE.CUSTOM:
            return prize.displayName[language] || prize.displayName.en;
        default:
            return '';
    }
}
export function getGamesByRaceGameGroupIds(race: CasinoRace) {
    if (!race?.gameGroupIds?.length) {
        return [];
    }
    const casinoGames = getStoreValue(casino.filteredGames);
    return casinoGames.filter((game) => game?.groupId && race.gameGroupIds.includes(game.groupId));
}

export function hasAvailableGamesInRace(race) {
    if (!race.gameGroupIds || race.gameGroupIds.length === 0) {
        return true;
    }
    const gamesByGroupId = getStoreValue(casino.gamesByGroupId);
    return race.gameGroupIds.some((game) => gamesByGroupId[game]);
}

export function getDurationDisplay(race) {
    const startDate = moment(race.startDate);
    const endDate = moment(race.endDate);
    return startDate.from(endDate, true);
}

export function raceStartEvent(raceStart) {
    logger.dev('CasinoCasinoRaceService', 'raceStartEvent', `Race start: ${JSON.stringify(raceStart)}`);
    stores.casinoRace.clientServerTimeDifference.set(moment().valueOf() - moment(raceStart.startDate).valueOf());
    raceStartInitialization(raceStart);
}

function raceStartInitialization(raceStart) {
    stores.casinoRace.active.races.set({
        ...getStoreValue(stores.casinoRace.active.races),
        [raceStart.activeRace.id]: raceStart.activeRace,
    });
    stores.casinoRace.active.scores.set({
        ...getStoreValue(stores.casinoRace.active.scores),
        [raceStart.activeRace.id]: raceStart.activeRace.score
            ? { ...raceStart.activeRace.score, userAlias: getStoreValue(stores.user)?.alias || '', isMe: true }
            : getDefaultScore(),
    });
    stores.casinoRace.isRaceSidebarVisible.set(true);
    setLeaderboardData(raceStart.activeRace, raceStart.activeRace.leaderboard);
}

export function setLeaderboardData(race: CasinoRace, leaderboard: CasinoRaceScore[]) {
    const personalizedLeaderboard = getPersonalizedLeaderboard(leaderboard);
    const userScore = personalizedLeaderboard.find((player) => player.isMe);
    if (userScore && userScore.spins && userScore.position) {
        stores.casinoRace.active.positions.set((position) => {
            position[race.id] = userScore.position as number;
        });
    }
    const updatedLeaderboard = race?.joined ? personalizedLeaderboard : leaderboard;
    stores.casinoRace.active.leaderboards.set((leaderboards) => {
        leaderboards[race.id] = updatedLeaderboard;
    });
    return updatedLeaderboard;
}

function getPersonalizedLeaderboard(leaderboard: CasinoRaceScore[]) {
    let personalizedLeaderboard = cloneDeep(leaderboard);
    let activePlayer = personalizedLeaderboard.find((player) => player.isActive);
    if (!activePlayer) {
        activePlayer = personalizedLeaderboard.find((player) => player.isMe);
    }
    if (!activePlayer) {
        personalizedLeaderboard = leaderboard.concat([getDefaultScore()]);
    } else {
        activePlayer.isActive = true;
    }
    return personalizedLeaderboard;
}

export function getDefaultScore(): CasinoRaceScore {
    const user = getStoreValue(stores.user);
    return {
        userAlias: user?.alias || '',
        spins: 0,
        points: 0,
        active: true,
        highestWinMultipliers: [],
        multiplierPoints: 0,
        multiplierPointsTotal: 0,
        isMe: true,
    };
}

export function raceEndEvent(raceEnd) {
    logger.dev('CasinoCasinoRaceService', 'raceEndEvent', `Race end: ${JSON.stringify(raceEnd)}`);
    const { race } = raceEnd;
    race.leaderboard = getPersonalizedLeaderboard(raceEnd.leaderboard);
    race.score = raceEnd.score;
    initializeCasinoRace();
}

export function racePointsEvent(score) {
    stores.casinoRace.active.scores.set({
        ...getStoreValue(stores.casinoRace.active.scores),
        [score.raceId]: { ...score, userAlias: getStoreValue(stores.user)?.alias || '' },
    });
}

export function raceMessageEvent(event) {
    if (event.key === 'casino-race-min-bet-message') {
        showBelowMinBetToast(event);
    }
}

function showBelowMinBetToast(message) {
    const { currency } = getStoreValue(stores.wallet) as any;
    const raceSettingsMinBet = currency && message?.raceSettings?.minBet?.[currency];
    const toast: Toast = {
        title: translate('Low bet', 'ui.casino.race'),
        size: 'size-medium',
        text: `${translate('Minimum stake for this casino race is {{ amount }}', 'casino.race', {
            amount: raceSettingsMinBet ?? RACE_MIN_BET_MULTIPLIERS[currency] * message.amountEur,
        })} ${currency}`,
        iconName: 'casino-race',
        type: 'warning',
        isImportant: true,
        closeOnLogout: true,
        lifespan: 3000,
        actions: [
            {
                default: true,
                name: translate('remind-next-time', 'casino.race'),
            },
            {
                name: translate('Got it', 'ui.common.toast'),
                action: disableMinBetReminder(message.raceId),
            },
        ],
    };

    showToast(toast);
}

export function getRaceMinBetDisplay(race: CasinoRace) {
    const currency = getActiveCurrency();
    const raceSettingsMinBet = race.raceSettings?.minBet?.[currency];
    return raceSettingsMinBet
        ? formattedAmountWithCurrency(raceSettingsMinBet)
        : getRaceCashDisplay(race.minBetAmount, RACE_MIN_BET_MULTIPLIERS);
}

export function getRaceCashDisplay(amount, rateMultipliers?) {
    const currency = getActiveCurrency();
    let amountForUc;
    if (RACE_TYPE.SLOT_TOURNAMENT && !amount) {
        return 0;
    }
    if (typeof amount === 'object') {
        amountForUc = amount[currency];
    } else {
        amountForUc = getAmountByStaticRate(Number(amount), currency, rateMultipliers);
    }
    return formattedAmountWithCurrency(amountForUc);
}

export function getRaceBackgroundUrl(race, params) {
    if (race.cmsTemplate) {
        return getCmsImageUrl({ fileName: race.cmsTemplate, params });
    }
    const gamesByGroupId = getStoreValue(casino.gamesByGroupId);
    const game = gamesByGroupId[race.gameGroupIds?.[0]];
    return getGameImageUrlForImage(race.backgroundImageName || game?.backgroundImageName, params);
}

export function setMobileRaceGameMenuVisible(isVisible) {
    stores.casinoRace.isMobileRaceGameMenuVisible.set(isVisible);
}
