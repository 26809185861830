import { Store } from '../stores/store/types';
import { useStore } from '../hooks/useStore';

export function storageGet<T>(key: string, defaultValue?: T): T {
    const localStorageValue = localStorage.getItem(key);
    try {
        return localStorageValue ? JSON.parse(localStorageValue) : defaultValue;
    } catch (error) {
        return (localStorageValue || defaultValue) as T;
    }
}

export function storageSet(key: string, value: unknown) {
    if (value === undefined) {
        return;
    }
    localStorage.setItem(key, JSON.stringify(value));
}

export function storageRemove(key: string) {
    localStorage.removeItem(key);
}

export function useStorage<T>(store: Store<T>, key: string): [T, (value: T) => void] {
    const [storeValue, setStoreValue] = useStore(store);

    function setValue(value: T) {
        if (value) {
            storageSet(key, value);
        } else {
            storageRemove(key);
        }

        setStoreValue(value);
    }

    return [storeValue, setValue];
}
