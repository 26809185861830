import { Transaction } from '../wallet/types';

export enum LIMIT_TYPE {
    DEPOSIT = 'deposit',
    INDIVIDUAL_BET = 'individual_bet',
    LOSS = 'loss',
    LOGIN_DURATION = 'login_duration',
    WAGERING = 'wager',
}

export enum LIMIT_PERIOD {
    NO_LIMIT = 'no_limit',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export enum ResponsibleGamingProduct {
    CASINO = 'casino',
    HORSE_RACING = 'horse_racing',
    POKER = 'poker',
    SPORTSBOOK = 'sportsbook',
}

export type ResponsibleGamingReminder = {
    action: string;
    id: string;
    userId: string;
    limit_end?: string;
    reason?: string;
    sessionStart: string;
    wallet_data: Transaction[];
    wallet_total?: number;
    kycToken?: string;
    kycTokenExpiry?: string;
};

type Limit = {
    amount: number;
    start_date: Date;
    end_date: Date;
    type: LIMIT_TYPE;
    period: LIMIT_PERIOD;
    remaining_amount: number;
    current_period_end_date: Date;
    consumption_unit: string;
};

export type UserLimit = { activeLimit: Limit; upcomingLimit: Limit };
export enum LimitPeriod {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export type RestrictionType = LIMIT_TYPE | 'session_reminder' | 'product_blocking' | 'self_exclusion';

export type ResponsibleGamingRestriction = {
    actions: ResponsibleGamingRestrictionAction[];
    expiryDate: string;
    extendPeriods?: number[];
    period: number | string;
    status: string;
    type: RestrictionType;
    amountRemaining?: number;
    amountTotal?: number;
    product?: ResponsibleGamingProduct | 'All';
};

export type ResponsibleGamingLimitConfig = {
    deposit: {
        max: Record<LimitPeriod, number>;
        min: number;
        recommended?: Record<LimitPeriod, number>;
    };
    individualBet: {
        min: number;
        max: number;
    };
    loss: {
        max: Record<LimitPeriod, number>;
        min: number;
    };
    wager: {
        max: Record<LimitPeriod, number>;
        min: number;
    };
    selfExclusionOptions: (number | 'untilFurtherNotice')[];
    timeoutOptions: Partial<Record<ResponsibleGamingProduct, number[]>>;
};

export enum ResponsibleGamingRestrictionAction {
    CHANGE_AMOUNT = 'Change amount',
    EXTEND_PERIOD = 'Extend period',
    REMOVE_LIMIT = 'Remove limit',
    RESTORE_LIMIT = 'Restore limit',
}
