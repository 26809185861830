import transform from 'css-to-react-native';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import trim from 'lodash/trim';
import React, { ReactElement } from 'react';
import { withForceLogin } from '../../../higher-order-components/cms/auth/ForceLogin';
import { withLoggedIn } from '../../../higher-order-components/cms/auth/LoggedIn';
import { withLoggedOut } from '../../../higher-order-components/cms/auth/LoggedOut';
import { withCheckCode } from '../../../higher-order-components/cms/bonuses/CheckCode';
import { withDynamicContent } from '../../../higher-order-components/cms/dynamic-content/DynamicContent';
import { withDynamicContentButton } from '../../../higher-order-components/cms/dynamic-content/DynamicContentButton';
import { withMenuLink } from '../../../higher-order-components/cms/menu/MenuLink';
import { withMenuPage } from '../../../higher-order-components/cms/menu/MenuPage';
import { withCloseModal } from '../../../higher-order-components/cms/modal/CloseModal';
import { withModal } from '../../../higher-order-components/cms/modal/Modal';
import { withOpenModal } from '../../../higher-order-components/cms/modal/OpenModal';
import { bonusRouteByAttribute, withOpenBonus } from '../../../higher-order-components/cms/navigation/OpenBonus';
import { withOpenLink } from '../../../higher-order-components/cms/navigation/OpenLink';
import { pageRouteByAttribute, withOpenPage } from '../../../higher-order-components/cms/navigation/OpenPage';
import { withScrollToId } from '../../../higher-order-components/cms/ScrollToId';
import { withShowFromDate } from '../../../higher-order-components/cms/ShowFromDate';
import { withShowOnDate } from '../../../higher-order-components/cms/ShowOnDate';
import { withShowOnDays } from '../../../higher-order-components/cms/ShowOnDays';
import { withShowOnWeek } from '../../../higher-order-components/cms/ShowOnWeek';
import { withShowUntilDate } from '../../../higher-order-components/cms/ShowUntilDate';
import AuthPayAndPlayButton from '../../auth/pay-and-play/button/AuthPayAndPlayButton';
import { withCheckSegment } from '../../../higher-order-components/cms/article/CheckSegment';
import { logger } from '../../../services/logger';
import asyncComponent from '../../async-component/AsyncComponent';
import CmsModal from '../modal/CmsModal';
import CmsSnippet from '../snippet/CmsSnippet';
import CmsSortableTable from '../sortable-table/CmsSortableTable';
import CmsTranslate from '../translate/CmsTranslate';
import UiButton from '../../ui/button/UiButton';

const CasinoV2JackpotHeaderCms = asyncComponent(
    () => import('../thrilltech-jackpot-header/CmsThrilltechJackpotHeader'),
);
const CasinoV2JackpotWinnersFeedCms = asyncComponent(
    () => import('../thrilltech-jackpot-winners-feed/CmsThrilltechJackpotWinnersFeed'),
);
const CmsBetslip = asyncComponent(() => import('../betslip/CmsBetslip'));
const CmsBonusCodeCounter = asyncComponent(() => import('../bonus-code-counter/CmsBonusCodeCounter'));
const CmsCasinoRtpCalculator = asyncComponent(() => import('../casino-rtp-calculator/CmsCasinoRtpCalculator'));
const CmsContactForm = asyncComponent(() => import('../contact-form/CmsContactForm'));
const CmsCountdown = asyncComponent(() => import('../countdown/CmsCountdown'));
const CmsJackpotFeed = asyncComponent(() => import('../jackpot-feed/CmsJackpotFeed'));
const CmsLeaderboard = asyncComponent(() => import('../leaderboard/CmsLeaderboard'));
const CmsLeaderboardCounter = asyncComponent(() => import('../leaderboard/counter/CmsLeaderboardCounter'));
const CmsLeaderboardOddsPerPeriod = asyncComponent(
    () => import('../leaderboard/odds-per-period/CmsLeaderboardOddsPerPeriod'),
);
const CmsPokerLeaderboard = asyncComponent(() => import('../poker-leaderboard/CmsPokerLeaderboard'));
const CmsQuizCollection = asyncComponent(() => import('../quiz-collection/CmsQuizCollection'));
const CmsSportMatchList = asyncComponent(() => import('../sport-match-list/CmsSportMatchList'));
const CmsTypeform = asyncComponent(() => import('../typeform/CmsTypeform'));
const CmsUserCount = asyncComponent(() => import('../user-count/CmsUserCount'));
const MarketingAdactGame = asyncComponent(() => import('../../marketing/adact-game/MarketingAdactGame'));
const PokerDownload = asyncComponent(() => import('../../poker/download/PokerDownload'));
const PokerInstantPlay = asyncComponent(() => import('../../poker/instant-play/PokerInstantPlay'));
const CmsSeoDescriptionFooter = asyncComponent(() => import('../seo-description-footer/CmsSeoDescriptionFooter'), {
    isSilent: true,
});

interface Props {
    html: string;
}

export default function CmsDomToReact({ html }: Props) {
    const headerElements = ['script', 'link'];

    const availableComponents = {
        'auth-pay-and-play-button': AuthPayAndPlayButton,
        CmsButton: UiButton,
        CmsBetslip,
        CmsModal,
        CmsTranslate,
        CmsCountdown,
        CmsSportMatchList,
        CmsQuizCollection,
        CmsTypeform,
        CmsContactForm,
        'adact-game': MarketingAdactGame,
        'casino-rtp-calculator': CmsCasinoRtpCalculator,
        'quiz-collection': CmsQuizCollection,
        'sport-campaign-leaderboard-total': CmsLeaderboard,
        'sport-campaign-leaderboard-daily': CmsLeaderboard,
        'sport-campaign-leaderboard-odds-per-period': CmsLeaderboardOddsPerPeriod,
        'sport-campaign-leaderboard-counter': CmsLeaderboardCounter,
        'bonus-code-counter': CmsBonusCodeCounter,
        'poker-download': PokerDownload,
        'poker-instant-play': PokerInstantPlay,
        'poker-leaderboard': CmsPokerLeaderboard,
        'landing-sortable-table': CmsSortableTable,
        'user-count': CmsUserCount,
        'jackpot-feed': CmsJackpotFeed,
        'seo-description-footer': CmsSeoDescriptionFooter,
        snippet: CmsSnippet,
        'thrilltech-jackpot-header': CasinoV2JackpotHeaderCms,
        'thrilltech-jackpot-winners-feed': CasinoV2JackpotWinnersFeedCms,
    };

    const componentWrapperByAttribute = {
        'logged-in': withLoggedIn,
        'logged-out': withLoggedOut,
        'force-login': withForceLogin,
        'show-on-date': withShowOnDate,
        'show-on-week': withShowOnWeek,
        'show-from-date': withShowFromDate,
        'show-until-date': withShowUntilDate,
        'show-on-days': withShowOnDays,

        modal: withModal,
        'open-modal': withOpenModal,
        'close-modal': withCloseModal,
        'check-segment': withCheckSegment,
        'check-code': withCheckCode,
        'scroll-to-id': withScrollToId,
        'open-link': withOpenLink,

        'dynamic-content': withDynamicContent,
        'dynamic-content-button': withDynamicContentButton,
        'menu-page': withMenuPage,
        'menu-link': withMenuLink,

        ...mapValues(pageRouteByAttribute, () => withOpenPage),
        ...mapValues(bonusRouteByAttribute, () => withOpenBonus),
    };

    const cmsComponents = Object.keys(availableComponents).reduce((components, key) => {
        components[key.toLowerCase()] = availableComponents[key];
        return components;
    }, {});

    const parserOptions = {
        replace({ name, attribs = {}, children }) {
            if (headerElements.includes(name) || !name) {
                return undefined;
            }
            let Component = cmsComponents[name];
            const props = getPatchedAttributes(attribs);
            const higherOrderComponents = Object.keys(componentWrapperByAttribute).filter(
                (attribute) => attribute in props,
            );

            if (!Component && !higherOrderComponents.length) {
                return undefined;
            }

            Component = Component || name;

            for (const attribute of higherOrderComponents) {
                Component = componentWrapperByAttribute[attribute](Component, String(props[attribute]));
            }
            return (
                <Component {...props} name={name}>
                    {domToReact(children, parserOptions as HTMLReactParserOptions)}
                </Component>
            );
        },
    };

    function getPatchedAttributes(attributes) {
        const props = cloneDeep(attributes);

        replacePatchedProps(props);
        if ('style' in props) {
            props.style = inlineCssToReact(props.style);
        }
        if (props.href && String(props.href).startsWith('#')) {
            delete props.href;
        } else if ('href' in props) {
            props['open-link'] = props.href;
            delete props.href;
        }

        return props;
    }

    function replacePatchedProps(props) {
        const propByAttribute = {
            class: 'className',
        };
        for (const attrib of Object.keys(props)) {
            if (Object.keys(propByAttribute).includes(attrib)) {
                props[propByAttribute[attrib]] = props[attrib];
                delete props[attrib];
            }
        }
    }

    function inlineCssToReact(style) {
        try {
            let parsedStyles = style.split(';');
            parsedStyles = parsedStyles.map((line) => trim(line).split(':'));
            parsedStyles = parsedStyles.filter((line) => line.length === 2);
            return transform(parsedStyles);
        } catch (error) {
            logger.error('CmsDomToReact', 'inlineCssToReact', error);
            return null;
        }
    }

    return parse(html, parserOptions as HTMLReactParserOptions) as ReactElement;
}
