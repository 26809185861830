import React from 'react';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import Wrapper from './styles';

interface Props {
    label?: string;
}

export default function LoaderPageCasino({ label }: Props) {
    return (
        <Wrapper>
            <div className="loader-wrap">
                {label && <h4>{label}</h4>}
                <UiDotsLoader />
            </div>
        </Wrapper>
    );
}
