import { getCasinoFilter, placeholderCasinoFilter } from '../services/casino/filter';
import { CasinoLobbySettings } from '../services/casino/lobby-setting';
import { CasinoGameLabel } from '../services/casino/game-label';
import { CasinoGameOperatingLanguage } from '../services/casino/operating-language';
import { CasinoStudio } from '../services/casino/studios';
import {
    CasinoCategory,
    CasinoCategorySubprovider,
    CasinoFeature,
    CasinoFilter,
    CasinoGame,
    CasinoGameLauncher,
    CasinoGameMetaData,
    CasinoJackpot,
    CasinoMaintenance,
    CasinoProvider,
    ClosedCasinoGames,
    GameLaunchPosition,
    GamePlayCurrency,
    GamePlayedCount,
    GameType,
    HighestDailyWins,
    UserActivity,
    UserHighestWin,
    UserStatistics,
    WinnerTicker,
} from '../services/casino/types';
import { LiveLobbyLauncherByType } from '../services/casino/provider-live-lobby-types';
import { createStores } from './store/utils';
import { storageGet } from '../services/storage';
import { LocalStorage } from '../services/local-storage/types';

export const casino = createStores({
    allGames: [] as CasinoGame[],
    filteredGames: [] as CasinoGame[],
    gamesById: {} as { [key: number]: CasinoGame },
    gamesByGroupId: {} as { [key: number]: CasinoGame },
    gamesBySlug: {} as { [key: string]: CasinoGame },
    gamesBySlugOld: {} as { [key: string]: CasinoGame },
    gamesByServerId: {} as { [key: string]: CasinoGame },
    gamesByCategoryId: {} as { [key: number]: CasinoGame[] },
    gamesMetaDataById: {} as { [key: string]: CasinoGameMetaData },
    recentlyPlayedGames: [] as CasinoGame[],
    gamesByTableId: {} as { [key: string]: CasinoGame },
    gamesByExternalId: {} as { [key: string]: CasinoGame },
    gamesByProviderAndServerId: {},
    gamesByProviderAndTableId: {},
    allGamesByProviderAndServerId: {} as { [key: string]: CasinoGame },
    allGamesByExternalId: {} as { [key: string]: CasinoGame },
    gameContainerSize: {} as { [key: string]: number },
    gamesPlayedCountsByGameId: {} as { [key: number]: GamePlayedCount },
    closedGames: {} as ClosedCasinoGames,

    categories: [] as CasinoCategory[],
    categoriesById: {} as { [key: number]: CasinoCategory },
    categoriesByCode: {} as { [key: string]: CasinoCategory },
    categoriesBySlug: {} as { [key: string]: CasinoCategory },

    providers: [] as CasinoProvider[],
    categorySubproviders: [] as CasinoCategorySubprovider[],
    providersById: {} as { [key: number]: CasinoProvider },
    liveLobbyLauncherByType: {} as LiveLobbyLauncherByType,
    subProvidersById: {} as { [key: number]: CasinoProvider },
    providersBySlug: {} as { [key: string]: CasinoProvider },
    features: [] as CasinoFeature[],
    featuresById: {} as { [key: number]: CasinoFeature },
    studios: [] as CasinoStudio[],
    studiosById: {} as { [key: number]: CasinoStudio },

    isInitialized: false as boolean,
    isSecondaryDataInitialized: false as boolean,
    isMobileSliderOpened: true as boolean,
    playForReal: false as boolean,
    shouldReload: false as boolean,
    hasActiveBonus: false as boolean,
    maintenance: {} as { [key: number]: CasinoMaintenance },
    filter: getCasinoFilter(),
    lobbySettings: {} as CasinoLobbySettings,

    jackpots: {} as { [key: string]: CasinoJackpot },
    jackpotsDisplayByGameId: {} as { [key: number]: string },

    userPreferences: createStores({
        filters: [] as CasinoFilter[],
        favoriteGameIds: [] as number[],
    }),
    activeFilter: { ...placeholderCasinoFilter } as CasinoFilter,
    originalFilter: {} as CasinoFilter,
    userStatistics: {} as UserStatistics,
    userActivity: {} as UserActivity,
    highestWins: [] as UserHighestWin[],
    highestDailyWins: [] as HighestDailyWins[],
    winnerTicker: {} as WinnerTicker,

    wagering: {
        balanceWagered: 0,
        wageringGoal: 0,
        wageringBonusAmount: 0,
        wageringBonusType: '',
    },

    isBonusSpent: false,

    // TODO these should have type and stuff, but also implementation sucks and we need to redo all of them
    visibleDesktopSidebarWidget: '',
    lastOpenedProductRoute: 'casino.slots' as string,
    isFullScreen: false,
    isPaybackBoosterCmsView: false,
    isPaybackBoosterCompactView: storageGet(LocalStorage.CASINO_BOOSTER_IS_COMPACT) === 'true',
    isPaybackBoosterWidgetVisible: false,
    isCasinoBlocked: false,
    isFilterModalOpen: false,

    gameModal: createStores({
        isVisible: false,
        game: undefined,
    }),
    gameLabels: [] as CasinoGameLabel[],
    operatingLanguages: [] as CasinoGameOperatingLanguage[],
    isCasinoLiveViewActive: false,
    digitalGameAccessPusher: undefined,
    latestGameLaunchPosition: { launchSource: 'DIRECT_LAUNCH' } as GameLaunchPosition,
    isGamePageDrawerOpen: false,
    gameTypes: [] as GameType[],
    gameTypesById: {} as { [key: number]: GameType },
    latestGamePromisedToNavigate: undefined as CasinoGame | undefined,
    activeGame: undefined as CasinoGameLauncher | undefined,
    gamePlayCurrency: 'USD' as GamePlayCurrency,
    shouldPauseAutoPlay: false,
});
