import { Country, Licence, Market } from '@staycool/location';
import { Language } from './language/types';
import { SportsLayout } from './layout/types';
import { TransactionState } from './payments/types';
import { ClosureReason, Industry, UserCountry } from './users/types';
import { TransactionProduct, TransactionState as WalletTransactionState } from './wallet/types';

export type User = {
    acceptTermsAndConditions: boolean;
    address: string;
    alias: string;
    askPersonalId: boolean;
    avatar?: string;
    birthDate: string;
    birthPlace: string;
    casinoClosed: boolean;
    casinoClosedReason?: ClosureReason;
    city: string;
    closed: boolean;
    country: Country;
    created: Date;
    email: string;
    eVerified: 'failed' | 'not_checked' | 'recommended' | 'requested' | 'verified';
    firstName: string;
    gender?: 'M' | 'F';
    hasAcceptedAgeAndPlayingOwnBehalf: boolean;
    industryId: number;
    isTest: boolean;
    jobTitle: string;
    language: Language;
    lastName: string;
    licence: Licence;
    loyaltyId?: string;
    market: Market;
    nationality: string;
    personalId: string;
    phoneNumber: string;
    phonePrefix: string;
    pokerClosed: boolean;
    pokerClosedReason?: ClosureReason;
    province?: string;
    racebookClosed?: boolean;
    racebookClosedReason?: ClosureReason;
    readonly id: string;
    registrationSource?: string;
    showFullAliasInLeaderboard?: boolean;
    showInLeaderboard: boolean;
    sportsbookClosed: boolean;
    sportsbookClosedReason?: ClosureReason;
    subscribeDirectMail: boolean;
    subscribeMailList: boolean;
    subscribePhoneCalls: boolean;
    subscribeSms: boolean;
    tempAccount?: any;
    verified: boolean;
    zip: string;
    isProfileReviewRequired: boolean;
    consents: {
        POLAR_KYC_MIGRATION?: boolean;
        JUEGOS_TERMS_AND_CONDITIONS_CONFIRMATION?: boolean;
        JUEGOS_PRIVACY_CONFIRMATION?: boolean;
        PEP_CONFIRMATION?: boolean;
    };
    requiredFields: Partial<User>;
    countries?: UserCountry[];
    industries?: Industry[];
    employerName?: string;
    sourceOfFunds?: string;
};

export type UserQueryParameters = {
    personalId?: boolean;
    extra?: boolean;
};

export enum FEATURE {
    ABOUT = 'ABOUT',
    ACCOUNT_LINKING = 'ACCOUNT_LINKING',
    ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
    ANIMATED_BACKGROUND = 'ANIMATED_BACKGROUND',
    AUTH_PROFILE_VALIDATOR = 'AUTH_PROFILE_VALIDATOR',
    AVATARS = 'AVATARS',
    BANNER_MESSAGE = 'BANNER_MESSAGE',
    BETBUILDER = 'BETBUILDER',
    BETBUILDER_REGIONS = 'BETBUILDER_REGIONS',
    BETSLIP_CONFIRM = 'BETSLIP_CONFIRM',
    BETSLIP_FULL_MARKET_NAME = 'BETSLIP_FULL_MARKET_NAME',
    BETSLIP_KEEP_SELECTIONS = 'BETSLIP_KEEP_SELECTIONS',
    BETSLIP_NAVIGATION = 'BETSLIP_NAVIGATION',
    BLOCKED_PRODUCTS_CHECK = 'BLOCKED_PRODUCTS_CHECK',
    BLOG = 'BLOG',
    BONUSES = 'BONUSES',
    BONUS_OFFERS = 'BONUS_OFFERS',
    BOOSTED_ODDS = 'BOOSTED_ODDS',
    CAMPAIGNS = 'CAMPAIGNS',
    CAMPAIGN_FEATURED_LINKS = 'CAMPAIGN_FEATURED_LINKS',
    CASINO = 'CASINO',
    CASINO_FILTERS = 'CASINO_FILTERS',
    CASINO_PAYBACK = 'CASINO_PAYBACK',
    CASINO_RACE = 'CASINO_RACE',
    CASINO_RULES = 'CASINO_RULES',
    CASINO_PLAY_FOR_FUN_ENABLED = 'PLAY_FOR_FUN_ENABLED',
    CASINO_THRILLTECH_JACKPOT = 'CASINO_THRILLTECH_JACKPOT',
    COMBO_CARDS = 'COMBO_CARDS',
    CONTESTS = 'CONTESTS',
    COOKIE_BOT = 'COOKIE_BOT',
    COOKIE_POLICY = 'COOKIE_POLICY',
    COOLBET_LEAGUE = 'COOLBET_LEAGUE',
    DARK_THEME = 'DARK_THEME',
    DEPOSIT = 'DEPOSIT',
    DEPOSIT_LOCK = 'DEPOSIT_LOCK',
    DEVICE_FINGERPRINT_SEON = 'DEVICE_FINGERPRINT_SEON',
    DISPLAY_DOLLAR_SIGN = 'DISPLAY_DOLLAR_SIGN',
    DOWNLOAD_ALL_MY_DATA = 'DOWNLOAD_ALL_MY_DATA',
    EDGE_TIER = 'EDGE_TIER',
    EVENT_TYPE_MAPPING = 'EVENT_TYPE_MAPPING',
    EXTERNAL_WALLET = 'EXTERNAL_WALLET',
    FAILED_TRANSACTION_REASONS = 'FAILED_TRANSACTION_REASONS',
    FEATURED_EVENTS = 'FEATURED_EVENTS',
    FINNISH_TRUST_NETWORK = 'FINNISH_TRUST_NETWORK',
    FOOTER = 'FOOTER',
    FREE_BET_DEDUCTION = 'FREE_BET_DEDUCTION',
    GAMBLING_STATISTICS = 'GAMBLING_STATISTICS',
    GAME_INFORMATION = 'GAME_INFORMATION',
    GENIUS_PLAYER = 'GENIUS_PLAYER',
    GEOCOMPLY = 'GEOCOMPLY',
    HEADER = 'HEADER',
    HEADER_MOBILE = 'HEADER_MOBILE',
    HIGHEST_TURNOVER = 'HIGHEST_TURNOVER',
    HORSE_RACING = 'HORSE_RACING',
    ID_DOCUMENT_UPLOAD_VERIFICATION = 'ID_DOCUMENT_UPLOAD_VERIFICATION',
    INBOX = 'INBOX',
    INCOMING_BETS = 'INCOMING_BETS',
    IP_CHECK = 'IP_CHECK',
    LANGUAGE_FLAG = 'LANGUAGE_FLAG',
    LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
    LEADER_BOARD = 'LEADER_BOARD',
    LEAGUE = 'LEAGUE',
    LIVE_LOBBY_LAUNCHER = 'LIVE_LOBBY_LAUNCHER',
    LOGIN = 'LOGIN',
    LOGIN_GEO_LOCATION = 'LOGIN_GEO_LOCATION',
    LOYALTY_CLUB_LINKING = 'LOYALTY_CLUB_LINKING',
    LOYALTY_VERIFICATION = 'LOYALTY_VERIFICATION',
    MARKETING_INCOME_ACCESS_AFFILIATE = 'MARKETING_INCOME_ACCESS_AFFILIATE',
    MANAGEMENT_PAGE = 'MANAGEMENT_PAGE',
    MATCH_OF_THE_DAY = 'MATCH_OF_THE_DAY',
    MATCH_TURNOVER = 'MATCH_TURNOVER',
    MOBILE_WEB_APP_INSTALL = 'MOBILE_WEB_APP_INSTALL',
    MULTI_CURRENCY_WALLET = 'MULTI_CURRENCY_WALLET',
    MY_ACCOUNT_EDITABLE = 'MY_ACCOUNT_EDITABLE',
    MY_ACCOUNT_GENDER = 'MY_ACCOUNT_GENDER',
    MY_ACCOUNT_INFO_MESSAGE = 'MY_ACCOUNT_INFO_MESSAGE',
    MY_ACCOUNT_LANGUAGE_SELECTION = 'MY_ACCOUNT_LANGUAGE_SELECTION',
    MY_CASINO = 'MY_CASINO',
    OPENBET_BETBUILDER = 'OPENBET_BETBUILDER',
    OTHER_LOGIN_OPTIONS = 'OTHER_LOGIN_OPTIONS',
    PARLAY_CARD = 'PARLAY_CARD',
    PAY_AND_PLAY = 'PAY_AND_PLAY',
    PAYMENT_PACKAGES = 'PAYMENT_PACKAGES',
    PLACE_BET = 'PLACE_BET',
    POKER = 'POKER',
    POKER_MARKETING_ONLY = 'POKER_MARKETING_ONLY',
    PREMATCH_STATISTICS = 'PREMATCH_STATISTICS',
    PROCESSES_I_AM_INVOLVED_IN = 'PROCESSES_I_AM_INVOLVED_IN',
    PROFILE_OCCUPATION_VALIDATION = 'PROFILE_OCCUPATION_VALIDATION',
    PROMOTIONS = 'PROMOTIONS',
    PUSH_TO_APP_INSTALL = 'PUSH_TO_APP_INSTALL',
    QUICKLINKS_SINGLE_TOP_LEAGUE = 'QUICKLINKS_SINGLE_TOP_LEAGUE',
    QUICKSTAKE_INCREMENTAL = 'QUICKSTAKE_INCREMENTAL',
    READ_AND_AGREE_TERMS = 'READ_AND_AGREE_TERMS',
    RECENTLY_PLAYED_GAMES = 'RECENTLY_PLAYED_GAMES',
    REFER_A_FRIEND = 'REFER_A_FRIEND',
    REFER_A_FRIEND_SHARE = 'REFER_A_FRIEND_SHARE',
    REGISTRATION = 'REGISTRATION',
    REGISTRATION_B2B = 'REGISTRATION_B2B',
    REGISTRATION_B2B_BASIC = 'REGISTRATION_B2B_BASIC',
    REGISTRATION_B2B_3RD_PARTY_TRANSFER_VERIFICATION = 'REGISTRATION_B2B_3RD_PARTY_TRANSFER_VERIFICATION',
    REPORT_FEEDBACK = 'REPORT_FEEDBACK',
    RESPONSIBLE_GAMING = 'RESPONSIBLE_GAMING',
    RESPONSIBLE_GAMING_BETTING_ADVICE = 'RESPONSIBLE_GAMING_BETTING_ADVICE',
    RESPONSIBLE_GAMING_COMMITMENT = 'RESPONSIBLE_GAMING_COMMITMENT',
    RESPONSIBLE_GAMING_DEPOSIT_LIMIT = 'RESPONSIBLE_GAMING_DEPOSIT_LIMIT',
    RESPONSIBLE_GAMING_INDIVIDUAL_BET_LIMIT = 'RESPONSIBLE_GAMING_INDIVIDUAL_BET_LIMIT',
    RESPONSIBLE_GAMING_LOGIN_DURATION_LIMIT = 'RESPONSIBLE_GAMING_LOGIN_DURATION_LIMIT',
    RESPONSIBLE_GAMING_LOSS_LIMIT = 'RESPONSIBLE_GAMING_LOSS_LIMIT',
    RESPONSIBLE_GAMING_PANIC_BUTTON = 'RESPONSIBLE_GAMING_PANIC_BUTTON',
    RESPONSIBLE_GAMING_PRODUCT_BLOCKING = 'RESPONSIBLE_GAMING_PRODUCT_BLOCKING',
    RESPONSIBLE_GAMING_SELF_ASSESSMENT = 'RESPONSIBLE_GAMING_SELF_ASSESSMENT',
    RESPONSIBLE_GAMING_SELF_EXCLUSION = 'RESPONSIBLE_GAMING_SELF_EXCLUSION',
    RESPONSIBLE_GAMING_SESSION_REMINDER = 'RESPONSIBLE_GAMING_SESSION_REMINDER',
    RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION = 'RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION',
    RESPONSIBLE_GAMING_WAGERING_LIMIT = 'RESPONSIBLE_GAMING_WAGERING_LIMIT',
    RTP_CALCULATOR = 'RTP_CALCULATOR',
    RTP_THUMBNAIL = 'RTP_THUMBNAIL',
    RTP_VARIANT = 'RTP_VARIANT',
    SAME_MATCH_MARKET_SET = 'SAME_MATCH_MARKET_SET',
    SAFER_WITH_COOLBET = 'SAFER_WITH_COOLBET',
    SECURE_WITHDRAWAL = 'SECURE_WITHDRAWAL',
    SEND_CURRENCY = 'SEND_CURRENCY',
    SEO = 'SEO',
    SEO_INTRO = 'SEO_INTRO',
    SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET = 'SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET',
    SESSION_DURATION = 'SESSION_DURATION',
    SET_YOUR_PASSWORD = 'SET_YOUR_PASSWORD',
    SIMPLE_COMBO = 'SIMPLE_COMBO',
    SPONSORSHIP_BANNER = 'SPONSORSHIP_BANNER',
    SPORTSBOOK = 'SPORTSBOOK',
    SPORT_MAIN_NAVIGATION_V2 = 'SPORT_MAIN_NAVIGATION_V2',
    SPORTSBOOK_SETTINGS = 'SPORTSBOOK_SETTINGS',
    SPORTSBOOK_SETTINGS_V2 = 'SPORTSBOOK_SETTINGS_V2',
    SPORTSBOOK_SETTINGS_ODDS_TYPE = 'SPORTSBOOK_SETTINGS_ODDS_TYPE',
    SPORTSBOOK_SETTINGS_TIME_FORMAT = 'SPORTSBOOK_SETTINGS_TIME_FORMAT',
    SPORTSBOOK_SETTINGS_AMERICAN_LAYOUT = 'SPORTSBOOK_SETTINGS_AMERICAN_LAYOUT',
    SPORTSBOOK_SETTINGS_COMPACT_VIEW = 'SPORTSBOOK_SETTINGS_COMPACT_VIEW',
    SPORTSBOOK_SETTINGS_WAGER_TO_WIN = 'SPORTSBOOK_SETTINGS_WAGER_TO_WIN',
    SPORT_LAYOUT_A = 'SPORT_LAYOUT_A',
    SPORT_PHONE_NAVIGATION_C = 'SPORT_PHONE_NAVIGATION_C',
    SPORT_TICKET_SHARING = 'SPORT_TICKET_SHARING',
    STATE_NOTICE = 'STATE_NOTICE',
    SUBSCRIPTION_SETTINGS_DIRECT_MAIL = 'SUBSCRIPTION_SETTINGS_DIRECT_MAIL',
    SUBSCRIPTION_SETTINGS_PHONE_CALLS = 'SUBSCRIPTION_SETTINGS_PHONE_CALLS',
    SUBSCRIPTION_SETTINGS_REVOKE_CONSENT = 'SUBSCRIPTION_SETTINGS_REVOKE_CONSENT',
    SUPPORT = 'SUPPORT',
    SYSTEM_ANALYTICS = 'SYSTEM_ANALYTICS',
    TEASERS = 'TEASERS',
    THEME_SINGLE_TONE = 'THEME_SINGLE_TONE',
    TICKET_TRACKING = 'TICKET_TRACKING',
    TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
    UNIFIED_LOGIN_AND_REGISTER = 'UNIFIED_LOGIN_AND_REGISTER',
    USER_BETTING_PERFORMANCE = 'USER_BETTING_PERFORMANCE',
    USER_FAVORITES = 'USER_FAVORITES',
    VIRTUAL_SPORTS = 'VIRTUAL_SPORTS', // the whole virtual sports product (betradar and highlight games combined)
    VOLATILITY_THUMBNAIL = 'VOLATILITY_THUMBNAIL',
    VS_HIGHLIGHT_GAMES = 'VS_HIGHLIGHT_GAMES',
    VS_HIGHLIGHT_GAMES_IIHF = 'VS_HIGHLIGHT_GAMES_IIHF',
    VS_HIGHLIGHT_GAMES_SOCCERBET = 'VS_HIGHLIGHT_GAMES_SOCCERBET',
    WAGER_LIMIT_LINK = 'WAGER_LIMIT_LINK',
    WELCOME_BONUS_LIST_POKER = 'WELCOME_BONUS_LIST_POKER',
    WELCOME_BONUS_LIST_SPORT = 'WELCOME_BONUS_LIST_SPORT',
    WELCOME_PAGE = 'WELCOME_PAGE',
    WELCOME_PAGE_ROUTE = 'WELCOME_PAGE_ROUTE',
    WINNERS_FEED = 'WINNERS_FEED',
    WINNERS_FEED_TOP_WINNERS = 'WINNERS_FEED_TOP_WINNERS',
    WINNER_TICKER = 'WINNER_TICKER',
    WITHDRAWALS = 'WITHDRAWALS',
    ZE_CHAT_BOT = 'ZE_CHAT_BOT',
    PAYMENT_METHODS_INFO = 'PAYMENT_METHODS_INFO',
    SMART_ID = 'SMART_ID',
    MANUAL_LOYALTY_INPUT = 'MANUAL_LOYALTY_INPUT',
    BETRADAR_LIVEBET_MATCH_TRACKER = 'BETRADAR_LIVEBET_MATCH_TRACKER',
    XTREMEPUSH = 'XTREMEPUSH',
    ESPORTS_WIDGET = 'ESPORTS_WIDGET',
    HORSE_RACING_LIVESTREAM = 'HORSE_RACING_LIVESTREAM',
    CAMPAIGN_WINNERS = 'CAMPAIGN_WINNERS',
    SMS2 = 'SMS2',
    BETSLIP_V2 = 'BETSLIP_V2',
    ADVANCE_WAGERING = 'ADVANCE_WAGERING',
    MINIMAL_USER_SIGNUP = 'MINIMAL_USER_SIGNUP',
    REGISTRATION_PROMO_CODE = 'REGISTRATION_PROMO_CODE',
    BETSLIP_SHARE = 'BETSLIP_SHARE',
    BLOCK_DESKTOP = 'BLOCK_DESKTOP',
    LOYALTY_PROGRAM = 'LOYALTY_PROGRAM',
    QUICK_DEPOSIT = 'QUICK_DEPOSIT',
    EXTERNAL_SPORTSBOOK = 'EXTERNAL_SPORTSBOOK',
}

export type TransactionsPageTransaction = {
    amount_uc: number;
    balance_uc?: number;
    created: Date;
    description: string | null;
    external_transaction_id: string;
    gameName?: string;
    id: string;
    isCancellable?: boolean;
    isLockedWithdrawal?: boolean;
    product: TransactionProduct;
    reason?: string | null;
    state: TransactionState | WalletTransactionState;
    type: string;
};
export const STORAGE_KEY_TOKEN = 'coolbet_token';

export enum SportsOddsFormat {
    DECIMAL = 'DECIMAL',
    AMERICAN = 'AMERICAN',
}

export enum SportsBetslipInputFormat {
    EUROPEAN = 'EUROPEAN',
    AMERICAN = 'AMERICAN',
}

export interface SportsUserSettings {
    user_id: string;
    is_american_time_format: boolean;
    layout: SportsLayout;
    odds_format: SportsOddsFormat;
    betslip_input_format: SportsBetslipInputFormat;
    betslip_quick_stakes: number[];
}
