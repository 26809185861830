import isEqual from 'lodash/isEqual';
import { DependencyList, useEffect, useState } from 'react';
import { WalletBalance } from './services/wallet/types';
import { Language } from './services/language/types';
import { storageGet } from './services/storage';
import { STORAGE_KEY_TOKEN, User } from './services/types';
import { cms } from './stores/cms';
import { twoFactor } from './stores/two-factor';
import { modals } from './stores/modals';
import { verification } from './stores/verification';
import { kyc } from './stores/kyc';
import { sports } from './stores/sports';
import { horseRacing } from './stores/horse-racing';
import { casinoRace } from './stores/casino-race';
import { geocomply } from './stores/geocomply';
import { crm } from './stores/crm';
import { registration } from './stores/registration';
import { blog } from './stores/blog';
import { maintenance } from './stores/maintenance';
import { payment } from './stores/payment';
import { marketing } from './stores/marketing';
import { PnpSettings } from './services/payments/types';
import { Store } from './stores/store/types';
import { createStores } from './stores/store/utils';
import { SHOW_SECTION } from './services/tournaments/types';
import { Toast } from './services/toast/types';
import { responsibleGaming } from './stores/responsible-gaming/responsible-gaming';
import { AppType } from './services/mobile-app/types';

export const stores = createStores({
    appeal: createStores({
        id: '',
        isFormOpen: false,
    }),
    rulesPageAvailable: null as null | boolean,
    rulesPagePreviousPath: '',
    appType: storageGet<AppType>('appType') || AppType.WEB,
    isOldBrowserWarningDisabled: storageGet('isOldBrowserWarningDisabled') || false,
    apiVersion: createStores({
        lastApiVersion: null,
        reloadRequired: false,
    }),
    cms,
    development: {
        status: 'ok',
        errors: {},
    },
    isHideErrorPopup: storageGet('development.hide-error-popup') || false,
    horseRacingDevMode: createStores({
        isEnabled: storageGet('development.horse-racing-dev-mode', false),
        mockedDate: storageGet('development.horse-racing-dev-mode.mocked-date', ''),
    }),
    twoFactor,
    isBootstrapped: false,
    isAuthenticated: false,
    modals,
    marketing,
    gdprDataDownloadToken: undefined as string | undefined,
    token: storageGet<string | undefined>(STORAGE_KEY_TOKEN),
    verification,
    user: undefined as User | undefined,
    hasPassword: true,
    kyc,
    sports,
    horseRacing,
    blockedProducts: {},
    casinoRace,
    wallet: undefined as WalletBalance | undefined,
    wallets: [] as WalletBalance[],
    language: undefined as unknown as Language,
    maintenance,
    promotions: [] as any[],
    features: [] as string[],
    arePromotionsFiltered: false,
    dynamicPromotionsCount: undefined,
    tournaments: createStores({
        showSection: SHOW_SECTION.ROUND_PREDICTIONS,
    }),
    bonuses: createStores({
        prefilledBonusCode: null,
    }),
    isPayAndPlayEnabled: false,
    payAndPlaySettings: undefined as PnpSettings | undefined,
    payAndPlayAuthType: undefined as string | undefined,
    payAndPlaySuccessMessageType: undefined as string | undefined,
    payment,
    ipCountry: undefined as unknown as string,
    responsibleGaming,
    betHistory: createStores({
        pageSize: 10,
    }),
    blog,
    registration,
    secondsPassedSinceApplicationStart: 0,
    session: createStores({
        endTime: undefined as Date | undefined | null,
        limitPeriodEndTime: undefined as Date | undefined,
        startTime: undefined as Date | undefined,
    }),
    pushNotification: undefined,
    deviceType: undefined, // TODO: check why we have 2 ways of setting device type
    applicationType: undefined as 'poker-download' | undefined,
    isBetslipOpen: false,
    isParlayBetslipOpen: false,
    isBetHistoryOpen: false,
    toasts: [] as Toast[],
    uuid: storageGet('uuid'),
    crm,
    lastActivityTime: undefined as string | undefined,
    isMobileNavigationVisible: true,
    isInputFocused: false,
    webSocketSubscriptions: [] as { topicSubscribe: string; params: any }[],
    useSharedStoreExample: null,
    geocomply,
    isGeoComplianceCheckPassed: false,
    isRacingAvailable: false,
    logoutViaStoreDate: null as Date | null,
    latestURLPromisedToNavigate: undefined as string | undefined,
});

window.stores = stores;

export function useStoreWithSelector<T, X>(
    store: Store<T>,
    selector: (state: T) => X,
    refresherValues: DependencyList = [],
    ensurer: (state: T) => boolean = () => true,
) {
    const [state, setState] = useState(selector(store.state));

    useEffect(() => {
        let previousState = state;
        setState(selector(store.state)); // re-evaluate necessary when the refresherValues change

        const stateSetter = (state) => {
            const newState = selector(state);
            const isSelectedStateChanged = !isEqual(previousState, newState);
            const isRenderAllowed = ensurer(state);

            if (isSelectedStateChanged && isRenderAllowed) {
                previousState = newState;
                setState(newState);
            }
        };

        store.subscribe(stateSetter);
        return () => store.unsubscribe(stateSetter);
    }, refresherValues);

    return [state, setState] as [X, typeof setState];
}
