import { Country, Market, MarketsByRegion } from '@staycool/location';
import {
    getAvailableJackpots,
    getUserAvailableJackpots,
    getRecentWins,
    optIn,
    optOut,
} from '../../microservices/casino-thrilltech-jackpots';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { casinoThrilltechJackpotStore } from '../../stores/casino-thrilltech-jackpot';
import { getStoreValue } from '../../stores/store/utils';
import { Jackpot, JackpotPotsByCurrency, JackpotWinEventData } from '../../types/casino-thrilltech-jackpot/types';
import { getActiveCurrency } from '../currency';
import { isFeatureAvailable } from '../feature';
import { REAL_LANGUAGE } from '../language/types';
import { logger } from '../logger';
import { FEATURE } from '../types';
import { getUserCountry } from '../users/country';
import { Currency } from '../wallet/types';

export async function refreshJackpots(refreshWins: boolean = true) {
    if (!isFeatureAvailable(FEATURE.CASINO_THRILLTECH_JACKPOT)) {
        return;
    }

    const ipCountry = getStoreValue(stores.ipCountry);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    const jackpotsDataById = getStoreValue(casinoThrilltechJackpotStore.jackpotsDataById);
    const currency = getActiveCurrency();

    let country = ipCountry as Country;
    if (isAuthenticated && user) {
        country = user.country;
    }

    try {
        const jackpots = await requestJackpots(country, currency, isAuthenticated);
        const potValuesByJackpotId: Record<string, JackpotPotsByCurrency> = jackpots.reduce((jackpotsMap, jackpot) => {
            jackpotsMap[jackpot.externalId] = {
                ...jackpotsDataById?.[jackpot.externalId],
                [currency]: jackpot.pots.reduce((potsMap, { id, amount }) => {
                    potsMap[id] = amount;
                    return potsMap;
                }, {}),
            };
            return jackpotsMap;
        }, {});

        casinoThrilltechJackpotStore.availableJackpots.set(jackpots);
        casinoThrilltechJackpotStore.jackpotsDataById.set(potValuesByJackpotId);
        refreshWins && (await refreshRecentWins());
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'refreshJackpots', error);
    }
}

async function requestJackpots(country: Country, currency: Currency, isAuthenticated: boolean): Promise<Jackpot[]> {
    if (isAuthenticated) {
        return await getUserAvailableJackpots({
            country: country,
            currency,
        });
    }
    return await getAvailableJackpots({
        country: country,
        currency,
    });
}

export async function loadThrilltechJackpots() {
    if (!isFeatureAvailable(FEATURE.CASINO_THRILLTECH_JACKPOT)) {
        return;
    }
    casinoThrilltechJackpotStore.availableJackpots.set([]);
    await refreshJackpots();
}

export async function refreshJackpotRecentWins(jackpotId: number) {
    const user = getStoreValue(stores.user);
    const ipCountry = getStoreValue(stores.ipCountry);
    try {
        const jackpotWins = await getRecentWins({
            jackpotId,
            country: (user?.country ?? ipCountry) as Country,
            currency: getActiveCurrency(),
        });
        casinoThrilltechJackpotStore.recentWinsByJackpotId.set({
            ...getStoreValue(casinoThrilltechJackpotStore.recentWinsByJackpotId),
            [jackpotId]: jackpotWins,
        });
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'refreshJackpotRecentWins', error);
    }
}

export async function refreshRecentWins() {
    const jackpotIds = getStoreValue(casinoThrilltechJackpotStore.availableJackpots).map(({ id }) => id);
    await Promise.all(jackpotIds.map(refreshJackpotRecentWins));
}

export function handleWinEvent(winEventData: JackpotWinEventData) {
    const winsQueue = getStoreValue(casinoThrilltechJackpotStore.winsQueue);
    if (getStoreValue(casinoThrilltechJackpotStore.currentWin)) {
        casinoThrilltechJackpotStore.winsQueue.set(winsQueue.concat(winEventData));
    } else {
        casinoThrilltechJackpotStore.currentWin.set(winEventData);
        casino.shouldPauseAutoPlay.set(true);
    }
}

export function updateWinsQueueOnClose() {
    const [nextWin, ...queuedWins] = getStoreValue(casinoThrilltechJackpotStore.winsQueue);
    if (nextWin) {
        casinoThrilltechJackpotStore.winsQueue.set(queuedWins);
        setTimeout(() => casinoThrilltechJackpotStore.currentWin.set(nextWin), 500);
    } else {
        casinoThrilltechJackpotStore.currentWin.set(undefined);
        casino.shouldPauseAutoPlay.set(false);
    }
}

function updateJackpotOptinValues({
    jackpotId,
    optInStatus,
    optInAmount,
}: {
    jackpotId: number;
    optInStatus: boolean;
    optInAmount?: number;
}) {
    const jackpots = getStoreValue(casinoThrilltechJackpotStore.availableJackpots);
    casinoThrilltechJackpotStore.availableJackpots.set(
        jackpots.map((jackpot) => (jackpot.id === jackpotId ? { ...jackpot, optInStatus, optInAmount } : jackpot)),
    );
}

export async function thrilltechJackpotOptIn(jackpotId: number, contributionValue?: number) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);

    if (!isAuthenticated || !user) {
        return;
    }

    try {
        await optIn({ jackpotId, contributionValue, userCurrency: getActiveCurrency() });
        updateJackpotOptinValues({
            jackpotId,
            optInStatus: true,
            optInAmount: contributionValue,
        });
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'thrilltechJackpotOptIn', error);
    }
}

export async function thrilltechJackpotOptOut(jackpotId: number) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);

    if (!isAuthenticated || !user) {
        return;
    }

    try {
        await optOut(jackpotId);
        updateJackpotOptinValues({
            jackpotId,
            optInStatus: false,
        });
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'thrilltechJackpotOptOut', error);
    }
}

export function getJackpotByGameId(gameId?: number) {
    const availableJackpots = getStoreValue(casinoThrilltechJackpotStore.availableJackpots);
    const sortedJackpots = [...availableJackpots].sort(({ optInStatus: aStatus }, { optInStatus: bStatus }) =>
        aStatus === bStatus ? 0 : aStatus ? -1 : 1,
    );
    return gameId ? sortedJackpots.find(({ games }) => games.find((game) => game.gameId === gameId)) : undefined;
}

export function getAssetsLanguage() {
    return MarketsByRegion.Latam.includes(getUserCountry() as Market) ? REAL_LANGUAGE.SPANISH : REAL_LANGUAGE.ENGLISH;
}
