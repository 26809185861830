import React from 'react';
import { translate } from '../../../../services/translate';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../../services/router';
import UiAlert from '../../../ui/alert/UiAlert';
import { getUserProductBlockReason } from '../../../../services/responsible-gaming';
import { PRODUCT } from '../../../../types/common';
import { ObjectValues } from '../../../../services/ts-utils';
import { ClosureReason } from '../../../../services/users/types';

interface Props {
    product: ObjectValues<typeof PRODUCT>;
}

export default function ResponsibleGamingAlertProductBlocking({ product }: Props) {
    if (product === PRODUCT.DEPOSIT) {
        return <UiAlert warning>{translate('all-product-block', 'ui.deposit')}</UiAlert>;
    }

    const closureReason = getUserProductBlockReason(product);

    if (!closureReason) {
        return null;
    }

    const translationKeyPrefix = {
        [PRODUCT.SPORTSBOOK]: 'sb-',
        [PRODUCT.CASINO]: '',
        [PRODUCT.POKER]: 'poker-',
        [PRODUCT.VIRTUAL_SPORTS]: 'vs-',
        [PRODUCT.RACEBOOK]: 'hr-',
    }[product];

    return (
        <UiAlert warning>
            {closureReason === ClosureReason.HAMPI && translate(`ui.account.${translationKeyPrefix}closed-hampi`)}
            {closureReason === ClosureReason.UNDERAGE && translate(`ui.account.${translationKeyPrefix}closed-underage`)}
            {closureReason === ClosureReason.SPELPAUS && translate(`ui.account.${translationKeyPrefix}closed-spelpaus`)}
            {closureReason === ClosureReason.MINCETUR && translate(`ui.account.${translationKeyPrefix}closed-mincetur`)}
            {closureReason === ClosureReason.MICHIGAN_GAMING_CONTROL_BOARD &&
                translate(`ui.account.${translationKeyPrefix}closed-mgcb`)}
            {closureReason === ClosureReason.PID_MISSING && translate('closed-pid-missing', 'ui.account')}
            {closureReason === ClosureReason.TIMEOUT && (
                <>
                    {translate(`ui.account.${translationKeyPrefix}closed-timeout1`)}
                    &nbsp;
                    <UiNavLink to={getRoute('responsible-gaming.set-limits')}>
                        {translate('closed-timeout2', 'ui.account')}
                    </UiNavLink>
                </>
            )}
            {closureReason === ClosureReason.SUPPORT && (
                <>
                    {translate(`ui.account.${translationKeyPrefix}closed-support1`)}
                    &nbsp;
                    <UiNavLink to={getRoute('support')}>{translate('closed-support2', 'ui.account')}</UiNavLink>
                    &nbsp;
                    {translate('closed-support3', 'ui.account')}
                </>
            )}
        </UiAlert>
    );
}
