import moment from 'moment';
import {
    ONSITE_MESSAGE_DISPLAY_TYPE,
    ONSITE_MESSAGE_TAB,
    OnsiteMessage,
    OnsiteMessageDisplayType,
    OnsiteMessageTab,
    OnsiteMessageWithCampaign,
} from '../microservices/crm/types';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';

function getMessageTabByDisplayType(message: OnsiteMessage) {
    if (!message.displayType || message.displayType === ONSITE_MESSAGE_DISPLAY_TYPE.NOTIFICATION) {
        return ONSITE_MESSAGE_TAB.NOTIFICATIONS;
    }

    return ONSITE_MESSAGE_TAB.MESSAGES;
}

export function isUnreadMessage(message: OnsiteMessage) {
    return !message.seenAt && !isExpiredMessage(message);
}

export function isExpiredMessage(message: OnsiteMessage) {
    return message.expiresAt && moment().isAfter(message.expiresAt);
}

export const onsiteMessageTabsTranslations = {
    [ONSITE_MESSAGE_TAB.ALL]: {
        tab: 'all',
        empty: 'no-new-messages',
    },
    [ONSITE_MESSAGE_TAB.NOTIFICATIONS]: {
        tab: 'notifications',
        empty: 'no-new-notifications',
    },
    [ONSITE_MESSAGE_TAB.MESSAGES]: {
        tab: 'messages',
        empty: 'no-new-messages',
    },
} as const;

export function getMessagesByType(allMessages: OnsiteMessageWithCampaign[]) {
    const messagesByType = {
        ...mapValues(ONSITE_MESSAGE_TAB, () => []),
        [ONSITE_MESSAGE_TAB.ALL]: allMessages,
        ...groupBy(allMessages, getMessageTabByDisplayType),
    } as Record<OnsiteMessageTab, OnsiteMessageWithCampaign[]>;

    return mapValues(messagesByType, (messages) => ({
        messages,
        unreadMessagesTotal: messages.filter(isUnreadMessage).length,
    }));
}

export const CRM_MESSAGE_STEP = 10;

export const getIsToasterMessage = (message?: OnsiteMessage) =>
    !message?.displayType ||
    !(
        [
            ONSITE_MESSAGE_DISPLAY_TYPE.INBOX_MESSAGE,
            ONSITE_MESSAGE_DISPLAY_TYPE.MODAL,
            ONSITE_MESSAGE_DISPLAY_TYPE.NEW_MESSAGES,
            ONSITE_MESSAGE_DISPLAY_TYPE.POP_UP,
        ] as OnsiteMessageDisplayType[]
    ).includes(message.displayType);
