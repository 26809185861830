import styled from 'styled-components';

export default styled.div`
    margin: 0 auto;
    max-width: 70px;
    text-align: center;
    white-space: nowrap;
    div {
        width: 15px;
        height: 15px;
        background-color: #333;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
        animation: sk-bouncedelay 1s infinite ease-in-out both;
    }
    .bounce1 {
        animation-delay: -0.32s;
        background: var(--color-secondary);
    }

    .bounce2 {
        animation-delay: -0.16s;
        background: var(--color-secondary);
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            transform: scale(0.3);
        }
        40% {
            transform: scale(0.8);
        }
    }
`;
